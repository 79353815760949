import React, { useContext, useState } from 'react';


import './Navbar.css';
import { userData } from '../../data/userData';
import { ThemeContext } from '../../theme/themeContext';

function Navbar() {
    const { themeData } = useContext(ThemeContext);

    return (
        <div className='navbar'>
            <div className='navbar--container'>
                <h1 style={{ color: themeData.secondary}}>{userData.name} </h1>
            </div>
            {/* <h1 style={{ color: theme.secondary}}>{userData.name} </h1> */}
        </div>
    );
}

export default Navbar;
