
export const userData = {
    name: 'James Tufarelli',
    title: "Full Stack Software Developer",
    desciption:"",
    about:"Hi, I'm James Tufarelli, a full-stack developer living in San Diego, CA. I began my career as a network engineer in the Air Force, but quickly realized I needed custom code to automate many of my daily tasks. I developed a passion for software as I began to write more code throughout my career. Today, I work as a self-taught software engineer focusing on many different types of technologies.",
    image: 'https://avatars.githubusercontent.com/u/8152401?v=4',
    github: 'https://github.com/minituff',
    twitter: 'https://twitter.com/minituff',
    linkedIn: 'https://www.linkedin.com/in/james-tufarelli',
    youtube: 'https://www.youtube.com/minituff',
    email: 'james@tufarelli.net',
}
