import React, { createContext, useState } from 'react'

import {redThemeDark, redThemeLight, bwThemeDark, bwThemeLight, blueThemeDark, blueThemeLight, brownThemeLight} from './themeData'

// This is the chosen theme
const themeDataRaw = {
    themeData: brownThemeLight
}


export const ThemeContext = createContext(themeDataRaw)

function ThemeContextProvider(props:any) {
    const [themeData, setTheme] = useState(themeDataRaw.themeData)

    const value = { themeData }

    return (
        <ThemeContext.Provider value={value}>
            {props.children}
        </ThemeContext.Provider>
    )
}


export default ThemeContextProvider