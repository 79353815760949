// import PropTypes from 'prop-types'
// import React, { Component } from 'react'
import { userData } from '../../data/userData';


import {
    FaTwitter,
    FaLinkedin,
    FaGithub,
    FaYoutube,
    FaEnvelope
} from 'react-icons/fa';

interface SocialsProps {
    color: string;
}

function Socials(props: SocialsProps) {

  return  (
            <>
                {userData.linkedIn && (
                    <a
                        href={userData.linkedIn}
                        target='_blank'
                        rel='noreferrer'
                    >
                        <FaLinkedin
                            className='landing--social'
                            style={{ color: props.color }}
                            aria-label='LinkedIn'
                        />
                    </a>
                )}
                {userData.github && (
                    <a
                        href={userData.github}
                        target='_blank'
                        rel='noreferrer'
                    >
                        <FaGithub
                            className='landing--social'
                            style={{ color: props.color }}
                            aria-label='GitHub'
                        />
                    </a>
                )}
                {userData.twitter && (
                    <a
                        href={userData.twitter}
                        target='_blank'
                        rel='noreferrer'
                    >
                        <FaTwitter
                            className='landing--social'
                            style={{ color: props.color }}
                            aria-label='Twitter'
                        />
                    </a>
                )}
                {userData.youtube && (
                    <a
                        href={userData.youtube}
                        target='_blank'
                        rel='noreferrer'
                    >
                        <FaYoutube
                            className='landing--social'
                            style={{ color: props.color }}
                            aria-label='YouTube'
                        />
                    </a>
                )}
                {userData.email && (
                    <a
                        href={`mailto:${userData.email}`}
                        target='_blank'
                        rel='noreferrer'
                    >
                        <FaEnvelope
                            className='landing--social'
                            style={{ color: props.color }}
                            aria-label='Email'
                        />
                    </a>
                )}

            </>
        )
    
}

export default Socials