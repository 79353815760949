import React, { useContext } from 'react'
import Button, { ButtonProps } from "@mui/material/Button"
import { userData } from '../../data/userData';
import { Socials} from '../../components';

import { ThemeContext } from '../../theme/themeContext';
import './Landing.css';
import { styled } from '@mui/system';

function Landing() {
    const { themeData } = useContext(ThemeContext);


    interface ThemedButtonProps extends ButtonProps {
        themeData: typeof themeData;
    }

    const ContactButton = styled(Button, {
        shouldForwardProp: (prop) => prop !== 'themeData',
    })<ThemedButtonProps>(({ themeData, theme }) => ({
        ...(themeData && {
            backgroundColor: themeData.primary,
            color: themeData.secondary,
            borderRadius: '30px',
            textTransform: 'inherit',
            textDecoration: 'none',  // Disable link underline
            width: '150px',
            height: '50px',
            fontSize: '1rem',
            fontWeight: '700',
            fontFamily: 'var(--primaryFont)',
            border: `3px solid ${themeData.primary}`,
            transition: '100ms ease-out',
            '&:hover': {
                backgroundColor: themeData.secondary,
                color: themeData.tertiary,
                border: `3px solid ${themeData.tertiary}`,
            },
        })
    }));

    return (
        <div className='landing'>
            <div className='landing--container'>
                <div
                    className='landing--container-left'
                    style={{ backgroundColor: themeData.primary }}
                >
                    <div className='lcl--content'>
                        <Socials color={themeData.secondary}></Socials>
                    </div>
                
                </div>
                <img
                    src={userData.image}
                    alt=''
                    className='landing--img'
                    style={{
                        opacity: `1`,
                        borderColor: themeData.secondary,
                    }}
                />
                <div
                    className='landing--container-right'
                    style={{ 
                        backgroundColor: themeData.secondary,
                        // backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: '100%',
                        height: '100%',
                    }}
                    
                >
                    <div
                        className='lcr--content'
                        style={{ color: themeData.tertiary }}
                    >
                        <div className='socials--bottom'>
                            <Socials color={themeData.primary}></Socials>
                        </div>

                        <h1>{userData.name}</h1>
                        <h6>{userData.title}</h6>
                        <p>{userData.desciption}</p>

                        <div className='lcr-buttonContainer'>
                            <h6>Got any questions?</h6>
                           
                            <a href={`mailto:${userData.email}`} style={{ textDecoration: "none" }}>
                                <ContactButton themeData={themeData}>Contact Me</ContactButton>
                            </a>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Landing;