
export const brownThemeLight = {
        type: 'dark',
        primary: '#BCAF9C',
        primary400: '#BCAF9C',
        primary600: '#69626D',
        primary80: '#6E614F',
        primary50: '##3D2F19',
        primary30: '#5A4A33',
        secondary: '#2C2A27',
        secondary70: '#212121b3',
        secondary50: '#21212180',
        tertiary: '#eaeaea',
        tertiary80: '#eaeaeacc',
        tertiary70: '#eaeaeab3',
        tertiary50: '#eaeaea80',
}



export const bwThemeLight = {
    type: 'light',
    primary: '#000000',
    primary400: '#000000',
    primary600: '#000000',
    primary80: '#000000cc',
    primary50: '#00000080',
    primary30: '#0000004d',
    secondary: '#EAEAEA',
    secondary70: '#EAEAEAb3',
    secondary50: '#EAEAEA80',
    tertiary: '#212121',
    tertiary80: '#212121cc',
    tertiary70: '#212121b3',
    tertiary50: '#21212180',
}

export const bwThemeDark = {
    type: 'dark',
    primary: '#B6B6B6',
    primary400: '#B6B6B6',
    primary600: '#B6B6B6',
    primary80: '#B6B6B6cc',
    primary50: '#B6B6B680',
    primary30: '#B6B6B64d',
    secondary: '#212121',
    secondary70: '#212121b3',
    secondary50: '#21212180',
    tertiary: '#eaeaea',
    tertiary80: '#eaeaeacc',
    tertiary70: '#eaeaeab3',
    tertiary50: '#eaeaea80',

}

export const blueThemeLight = {
    type: 'light',
    primary: '#545fc4',
    primary400: '#6e76c7',
    primary600: '#3644c9',
    primary80: '#545fc4cc',
    primary50: '#545fc480',
    primary30: '#545fc44d',
    secondary: '#eaeaea',
    secondary70: '#eaeaeab3',
    secondary50: '#eaeaea80',
    tertiary: '#212121',
    tertiary80: '#212121cc',
    tertiary70: '#212121b3',
    tertiary50: '#21212180',

}
export const blueThemeDark = {
    type: 'dark',
    primary: '#545fc4',
    primary400: '#6e76c7',
    primary600: '#3644c9',
    primary80: '#545fc4cc',
    primary50: '#545fc480',
    primary30: '#545fc44d',
    secondary: '#212121',
    secondary70: '#212121b3',
    secondary50: '#21212180',
    tertiary: '#eaeaea',
    tertiary80: '#eaeaeacc',
    tertiary70: '#eaeaeab3',
    tertiary50: '#eaeaea80',

}

export const redThemeLight = {
    type: 'light',
    primary: '#f03939',
    primary400: '#dd4d4d',
    primary600: '#ea2424',
    primary80: '#f03939cc',
    primary50: '#f0393980',
    primary30: '#f039394d',
    secondary: '#eaeaea',
    secondary70: '#eaeaeab3',
    secondary50: '#eaeaea80',
    tertiary: '#212121',
    tertiary80: '#212121cc',
    tertiary70: '#212121b3',
    tertiary50: '#21212180',

}
export const redThemeDark = {
    type: 'dark',
    primary: '#f03939',
    primary400: '#dd4d4d',
    primary600: '#ea2424',
    primary80: '#f03939cc',
    primary50: '#f0393980',
    primary30: '#f039394d',
    secondary: '#212121',
    secondary70: '#212121b3',
    secondary50: '#21212180',
    tertiary: '#eaeaea',
    tertiary80: '#eaeaeacc',
    tertiary70: '#eaeaeab3',
    tertiary50: '#eaeaea80',

}
