import { HashRouter } from "react-router-dom";
import { NavBar, Landing} from './components';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <HashRouter>
        <NavBar></NavBar>
        <Landing></Landing>
      </HashRouter>
    </div>
  );
}

export default App;
